<mat-form-field appearance="outline" [class.submited]="wasSubmited" [ngClass]="'app-type--' + appType">
  <mat-label *ngIf="hasLabel">{{ placeholder }}</mat-label>
  <ng-container [ngSwitch]="appType">
    <ng-container *ngSwitchCase="appTypes.PHONE">
      <input
        matInput
        class="w-full flex-grow"
        appPhoneMask
        type="text"
        [formControl]="control"
        [(ngModel)]="value"
        [disabled]="!!(disabled$ | async)"
        (change)="changeEvent($event)"
        (focus)="focusEvent.emit()"
      />
    </ng-container>

    <ng-container *ngSwitchCase="appTypes.UNZR">
      <input
        matInput
        class="w-full flex-grow"
        appUnzrMask
        type="text"
        [formControl]="control"
        [(ngModel)]="value"
        [disabled]="!!(disabled$ | async)"
        (change)="changeEvent($event)"
        (focus)="focusEvent.emit()"
      />
    </ng-container>

    <ng-container *ngSwitchCase="appTypes.PASSPORT_OLD">
      <input
        matInput
        class="w-full flex-grow"
        mask="AA000000"
        [patterns]="olPassportMaskPattern"
        type="text"
        [formControl]="control"
        [(ngModel)]="value"
        [disabled]="!!(disabled$ | async)"
        (change)="changeEvent($event)"
        (focus)="focusEvent.emit()"
      />
    </ng-container>

    <ng-container *ngSwitchCase="appTypes.PASSPORT_NEW">
      <input
        matInput
        class="w-full flex-grow"
        mask="000000000"
        type="text"
        [formControl]="control"
        [(ngModel)]="value"
        [disabled]="!!(disabled$ | async)"
        (change)="changeEvent($event)"
        (focus)="focusEvent.emit()"
      />
    </ng-container>

    <ng-container *ngSwitchCase="appTypes.EDRPO">
      <input
        matInput
        class="w-full flex-grow"
        type="text"
        [formControl]="control"
        [(ngModel)]="value"
        [disabled]="!!(disabled$ | async)"
        (change)="changeEvent($event)"
        (focus)="focusEvent.emit()"
      />
    </ng-container>

    <ng-container *ngSwitchDefault>
      <input
        matInput
        class="w-full flex-grow"
        [type]="currentType"
        [formControl]="control"
        [(ngModel)]="value"
        [disabled]="!!(disabled$ | async)"
        (change)="changeEvent($event)"
        (focus)="focusEvent.emit()"
      />
    </ng-container>
  </ng-container>
  <mat-icon *ngIf="icon" [svgIcon]="icon" />
  <span *ngIf="type === 'password'" class="absolite top-8 rifht-8 underline text-small cursor-pointer" (click)="toggleHiddenText()">
    {{ currentHideText }}
  </span>

  <mat-error *ngIf="wasSubmited">
    <span>{{ control.valueChanges | async | controlError: control }}</span>
  </mat-error>
</mat-form-field>
