import { Directive, HostListener } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  selector: '[appUnzrMask]',
  standalone: true,
})
export class UnzrMaskDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = <HTMLInputElement>event.target
    let value = input.value.replace(/\D/g, '')

    if (value.length > 8) {
      value = `${value.substring(0, 8)}-${value.substring(8, 12)}`
    }

    this.ngControl.control?.setValue(value)
  }
}
