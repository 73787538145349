import { PhoneMaskDirective } from '@app/core/directives/phone-mask.directive'
import { ControlErrorPipe } from '@app/core/pipes/control-error.pipe'
import { SvgIcon } from '@app/shared/svg-icons/svg-icons.enum'
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { BehaviorSubject } from 'rxjs'

import { AppTypeEnum } from './shared/types'

import { AsyncPipe, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common'
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, forwardRef } from '@angular/core'
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'

import { UnzrMaskDirective } from 'src/app/core/directives/unzr-mask.directive'

import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [
    SvgIconsModule,
    MatIconModule,
    FormsModule,
    NgIf,
    MatFormField,
    MatInputModule,
    AsyncPipe,
    NgSwitch,
    NgSwitchCase,
    PhoneMaskDirective,
    NgSwitchDefault,
    ControlErrorPipe,
    ReactiveFormsModule,
    UnzrMaskDirective,
    NgxMaskDirective,
    NgClass,
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    provideNgxMask(),
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() type = 'text'
  @Input() appType!: AppTypeEnum
  @Input() placeholder = 'Search'
  @Input() icon!: SvgIcon

  @Input() nonControlValue = ''

  @Input() isNonControl = false
  @Input() wasSubmited: boolean | undefined | null
  @Input() control: FormControl | any = new FormControl()

  @Input() hasLabel = true

  @Input() isPasswordLogic!: boolean

  @Output() nonControlEvent: EventEmitter<string> = new EventEmitter<string>()
  @Output() focusEvent: EventEmitter<void> = new EventEmitter<void>()

  svgIcons = SvgIcon
  appTypes = AppTypeEnum

  private val = ''
  disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  onChange: (value: string) => void = () => {}
  onTouched = (): void => {}

  currentType!: string

  get currentHideText(): string {
    return this.currentType === 'password' ? 'Показати' : 'Сховати'
  }

  get value(): string {
    return this.val
  }

  set value(val: string) {
    if (val !== this.val) {
      this.val = val
      this.onChange(val)
      this.onTouched()
    }
  }

  readonly olPassportMaskPattern = {
    A: { pattern: new RegExp('[А-ЩЬЮЯІЇЄа-щьюяіїє]') },
    '0': { pattern: new RegExp('[0-9]') },
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['nonControlValue'] && !changes['nonControlValue'].firstChange) {
      this.writeValue(this.nonControlValue)
    }
  }

  ngOnInit(): void {
    if (this.isNonControl) {
      this.writeValue(this.nonControlValue)
    }

    this.currentType = this.type
  }

  toggleHiddenText(): void {
    this.currentType = this.currentType === 'password' ? 'text' : 'password'
  }

  writeValue(value: string): void {
    this.value = value
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  changeEvent(event: Event): void {
    this.nonControlEvent.emit((<HTMLInputElement>event.target).value)
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$.next(isDisabled)
  }
}
